<template>
  <el-dialog title="高级设置" :visible.sync="dialogVisible" class="my_dialog">
    <div style="height: 40vh">
      <span class="a-fs-16 a-c-333 a-fw-b">设备过载电流</span>
      <div class="a-flex-ccfs">
        <le-input-num
          v-model="station.overloadElectricityNormal"
          label="普通充电(A)"
        ></le-input-num>
        <le-input-num v-model="station.overloadElectricityMax" label="大功率充电(A)"></le-input-num>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handlerSumbit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false,
    },
    stationId: {
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      station: {
        overloadElectricityNormal: 3.5,
        overloadElectricityMax: 8,
      },
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
    },
    dialogVisible: {
      handler(val, oldval) {
        if (val != oldval) {
          this.$emit("update:visible", val);
        }
      },
    },
    stationId: {
      handler(val) {
        if (val) {
          this.getStationDetail();
        }
      },
    },
  },
  methods: {
    /**
     * 获取站点详细信息
     */
    getStationDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getStationDetail,
          params: {
            stationId: this.stationId,
          },
        })
        .then(({ data }) => {
          this.station = data.station;
        });
    },

    handlerSumbit() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.setStationElectric,
          params: {
            stationId: this.stationId,
            overloadElectricityNormal: this.station.overloadElectricityNormal,
            overloadElectricityMax: this.station.overloadElectricityMax,
          },
        })
        .then(() => {
          this.dialogVisible = false;
          this.$emit("changeSetting");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.my_dialog {
  /deep/ .el-dialog__header {
    border-bottom: 1px solid #ebf0f5;
  }
  /deep/ .el-dialog__footer {
    border-top: 1px solid #ebf0f5;
  }
}
</style>
