<template>
  <el-card class="a-p-16 a-fs-14 a-c-333" style="width: 640px; padding: 16px !important">
    <div class="a-w-100 a-flex-rsbc">
      <div class="a-flex-rcc">
        <span class="a-fs-16 a-fw-b">{{ price.name }}</span>
        <div class="s-tag-blue a-ml-08 a-plr-7">
          {{ price.status == 0 ? "生效中" : "已停用" }}
        </div>
        <div v-if="price.type == 1 && power.length > 0" class="s-tag-orange a-ml-08 a-plr-7">
          <span>功率计费</span>
        </div>
        <div class="s-tag-orange a-ml-08 a-plr-7">
          <span>{{ price.settingType == 2 ? "按金额" : "按时长" }}</span>
        </div>
      </div>
      <div v-if="!readOnly" class="a-flex-rcc">
        <div class="a-flex-rcc a-cursor-p" @click="handlerEditPrice">
          <img src="../../../assets/icon/option-edit.png" class="a-wh-16" />
          <span class="a-fs-14 a-c-blue a-ml-08">编辑</span>
        </div>
        <div class="a-flex-rcc a-cursor-p a-ml-16" @click="handlerChoosePrice">
          <img src="../../../assets/icon/option-edit.png" class="a-wh-16" />
          <span class="a-fs-14 a-c-blue a-ml-08">选择</span>
        </div>
      </div>
    </div>
    <div class="a-mt-16">
      <span>{{ price.description }}</span>
    </div>
    <el-row :gutter="20" class="a-mt-16">
      <el-col :span="14">
        <div class="a-flex-rfsc">
          <span class="a-c-999">起步费用</span>
          <span class="a-ml-16">
            {{ util.numFormat(price.baseAmount) }}元/{{
              price.baseMinutes % 60 == 0
                ? price.baseMinutes / 60 + "小时"
                : price.baseMinutes + "分钟"
            }}
          </span>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="a-flex-rfsc">
          <span class="a-c-999">免费时长</span>
          <span class="a-ml-16">{{ price.hesitationMinutes }}分钟</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="a-mt-8">
      <el-col :span="14">
        <div class="a-flex-rfsc">
          <span class="a-c-999">加时费用</span>
          <span class="a-ml-16">
            {{ util.numFormat(price.stepAmount) }}元/{{
              price.stepMinutes % 60 == 0
                ? price.stepMinutes / 60 + "小时"
                : price.stepMinutes + "分钟"
            }}
          </span>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="a-flex-rfsc">
          <span class="a-c-999">最长时长</span>
          <span class="a-ml-16">
            {{
              price.maxMinutes % 60 == 0
                ? price.maxMinutes / 60 + "小时"
                : price.maxMinutes + "分钟"
            }}
          </span>
        </div>
      </el-col>
    </el-row>
    <div class="a-mt-8">
      <span class="a-c-orange">共有{{ stationSize }}个站点正在使用该定价</span>
    </div>
    <div v-if="power.length > 0" class="a-mt-24">
      <el-table :data="power" :highlight-current-row="true">
        <el-table-column label="功率" align="left">
          <template slot-scope="{ row }">
            <span>{{ row.minPower }} - {{ row.maxPower }}瓦</span>
          </template>
        </el-table-column>
        <el-table-column :label="price.settingType === 2 ? '起步费用' : '起步时长'" align="left">
          <template slot-scope="{ row }">{{ row.baseAmount }}元/{{ row.baseMinutes }}分钟</template>
        </el-table-column>
        <el-table-column :label="price.settingType === 2 ? '加时费用' : '加时时长'" align="left">
          <template slot-scope="{ row }">{{ row.stepAmount }}元/{{ row.stepMinutes }}分钟</template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import util from "../../../utils/util.js";
export default {
  props: {
    priceId: {
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      util: util,
      price: {},
      power: [],
      stationSize: 0,
    };
  },
  watch: {
    priceId: {
      handler(val) {
        if (val) {
          this.getPlanDetail();
          this.getPlanUserStationSize();
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 获取定价详情
     */
    getPlanDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getPlanDetail,
          params: {
            chargingPlanId: this.priceId,
          },
        })
        .then(({ data }) => {
          this.price = {
            ...data,
            baseAmountYuan: (data.baseAmount / 100).toFixed(2),
            stepAmountYuan: (data.stepAmount / 100).toFixed(2),
          };
          this.getPlanPower();
        });
    },
    /**
     * 获取功率计费详情
     */
    getPlanPower() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getPlanPower,
          params: {
            chargingPlanId: this.priceId,
          },
        })
        .then(({ data }) => {
          this.power = data.map((v) => {
            const childResult = {
              ...v,
              baseAmount: (Math.round((this.price.baseAmount * v.chargeScale) / 100) / 100).toFixed(
                2
              ),
              baseMinutes: this.price.baseMinutes,
              stepAmount: (Math.round((this.price.stepAmount * v.chargeScale) / 100) / 100).toFixed(
                2
              ),
              stepMinutes: this.price.stepMinutes,
              minPower: v.minPower,
              maxPower: v.maxPower,
            };
            if (this.price.settingType === 2) {
              childResult.baseAmount = this.price.baseAmount / 100;
              childResult.baseMinutes = ((this.price.baseMinutes / v.chargeScale) * 100).toFixed();
              childResult.stepAmount = this.price.stepAmountYuan;
              childResult.stepMinutes = ((this.price.stepMinutes / v.chargeScale) * 100).toFixed();
            }
            return childResult;
          });
        });
    },
    /**
     * 获取定价相关的站点数
     */
    getPlanUserStationSize() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getPlanUserStationSize,
          params: {
            chargingPlanId: this.priceId,
          },
        })
        .then(({ data }) => {
          this.stationSize = data;
        });
    },

    /**
     * 编辑定价
     */
    handlerEditPrice() {
      if (this.priceId) {
        this.$router.push({
          path: "/price/price-edit",
          query: {
            priceId: this.priceId,
          },
        });
      }
    },

    handlerChoosePrice() {
      this.$emit("choosePrice");
    },
  },
};
</script>

<style scoped></style>
