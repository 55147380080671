<template>
  <div class="app-body" style="overflow: scroll">
    <div class="a-flex-rsbc">
      <bread-crumb></bread-crumb>
      <div class="a-flex-rfsc">
        <template
          v-if="
            station.companyId == company.id ||
            station.submitterCompanyId == companyId ||
            company.id == topCompanyId
          "
        >
          <el-button
            v-if="station.status != 3"
            type="danger"
            icon="el-icon-close"
            plain
            @click="cancelStation()"
          >
            撤销站点
          </el-button>
        </template>
      </div>
    </div>
    <div class="a-w-100 a-fs-14 a-p-24 a-br-top-4 a-bg-white">
      <el-descriptions size="medium">
        <template #title>
          <div class="a-flex-rfsc">
            <span>{{ station.name }}</span>
            <el-tag class="a-ml-08">{{ station.statusText }}</el-tag>
          </div>
        </template>
        <template #extra>
          <div class="a-flex-rfsc a-cursor-p" @click="handlerEditStationName">
            <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
            <span class="a-c-blue a-ml-08">修改站点名称</span>
          </div>
        </template>
        <el-descriptions-item label="站点地址">{{ station.address }}</el-descriptions-item>
        <el-descriptions-item label="所属商户">
          <le-jumpto-detail
            url="/franchisee/franchisee-detail"
            class="a-c-blue"
            :d-query="{ code: station.companyId }"
          >
            <span>{{ station.companyName }}</span>
          </le-jumpto-detail>
        </el-descriptions-item>
        <el-descriptions-item label="地图点位">
          <div class="a-ml-16 a-c-blue a-flex-rcc a-cursor-p" @click="handlerShowLocation">
            <span>查看</span>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="a-flex-1">
      <el-tabs v-model="tabName" class="my-tab">
        <el-tab-pane lazy label="统计信息" name="tjxx">
          <station-detail-stat :station-id="stationId"></station-detail-stat>
        </el-tab-pane>
        <el-tab-pane label="设备列表" name="device">
          <station-detail-device :station-id="stationId"></station-detail-device>
        </el-tab-pane>
        <el-tab-pane label="站点定价" name="price">
          <div class="a-br-4 a-w-100 a-bg-white a-p-24 a-h-100">
            <station-detail-price
              :station-id="stationId"
              :company-id="station.companyId"
              :price-id="station.chargingPlanId"
            ></station-detail-price>
          </div>
        </el-tab-pane>
        <el-tab-pane label="项目详情" name="project">
          <div class="a-br-4 a-w-100 a-bg-white a-p-24 a-h-100">
            <project-confirmed :income-id="station.incomeDistriId"></project-confirmed>
          </div>
        </el-tab-pane>
        <el-tab-pane label="高级设置" name="setting">
          <div class="a-br-4 a-w-100 a-bg-white a-p-24 a-h-100">
            <station-detail-setting :station-id="stationId"></station-detail-setting>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作历史" name="history">
          <div class="a-br-4 a-w-100 a-bg-white a-p-10 a-h-100">
            <station-detail-history :station-id="stationId"></station-detail-history>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="修改站点名称"
      destroy-on-close
      :visible.sync="changeNameVisible"
      class="my_dialog"
    >
      <le-input v-model="station.name" large></le-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeNameVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlerEditStationNameSumbit">确 定</el-button>
      </span>
    </el-dialog>
    <le-address-map
      :visible.sync="dialogVisibleMap"
      :position-object.sync="positionObject"
    ></le-address-map>
  </div>
</template>

<script>
import { mapState } from "vuex";

import leAressMap from "../components/choose/le-address-map.vue";
import ProjectConfirmed from "../project/project-confirmed.vue";
import stationDetailDevice from "./station-detail-device.vue";
import StationDetailHistory from "./station-detail-history.vue";
import stationDetailPrice from "./station-detail-price.vue";
import StationDetailSetting from "./station-detail-setting.vue";
import stationDetailStat from "./station-detail-stat.vue";

export default {
  name: "StationDetail",

  components: {
    "station-detail-stat": stationDetailStat,
    "station-detail-device": stationDetailDevice,
    "station-detail-price": stationDetailPrice,
    "project-confirmed": ProjectConfirmed,
    "station-detail-setting": StationDetailSetting,
    "le-address-map": leAressMap,
    "station-detail-history": StationDetailHistory,
  },

  data() {
    return {
      companyId: "",
      stationId: null,
      topCompanyId: null,
      station: {
        chargingPlanId: "",
        companyId: "",
      },
      tabName: "tjxx",
      changeNameVisible: false,
      dialogVisibleMap: false,
      positionObject: {
        lat: "",
        lng: "",
        name: "",
        address: "",
      },
    };
  },

  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },

  mounted() {
    this.stationId = parseInt(this.$route.query.stationId);
    this.getStationDetail();
  },

  methods: {
    /**
     * 获取站点详细信息
     */
    getStationDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getStationDetail,
          params: {
            stationId: this.stationId,
          },
        })
        .then(({ data }) => {
          this.station = data.station;
          this.positionObject = {
            lat: this.station.lat,
            lng: this.station.lng,
            name: this.station.name,
            address: this.station.address,
          };
          this.getTopCompanyId(this.station.companyId);
        });
    },
    /**
     * 获取顶级
     */
    getTopCompanyId(stationOwnerCompanyId) {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getTopCompanyIdWithCompanyId,
          params: {
            companyId: stationOwnerCompanyId,
          },
        })
        .then(({ data }) => {
          this.topCompanyId = data;
        });
    },
    /**
     * 编辑站点名称
     */
    handlerEditStationName() {
      this.changeNameVisible = true;
    },

    handlerEditStationNameSumbit() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.changeStationName,
          params: {
            stationId: this.stationId,
            name: this.station.name,
          },
        })
        .then(() => {
          this.changeNameVisible = false;
          this.$message.success("修改站点名称成功");
          this.getStationDetail();
        });
    },
    /**
     * 显示站点地址
     */
    handlerShowLocation() {
      this.$set(this, "dialogVisibleMap", true);
    },

    cancelStation() {
      this.$confirm("是否确认撤销站点?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.cancelCreateStation,
            params: {
              stationId: this.stationId,
            },
          })
          .then(() => {
            this.$message.success("撤站成功");
            this.getStationDetail();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-tab {
  height: 100%;
  display: flex;
  flex-direction: column;

  /deep/ .el-tabs__nav-wrap {
    background-color: #fff;
    padding: 0 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &::after {
      background-color: white !important;
    }
  }

  /deep/ .el-tabs__content {
    flex: 1;

    .el-tab-pane {
      height: 100%;
    }
  }
}

.my_dialog {
  /deep/ .el-dialog {
    width: 454px;
  }

  /deep/ .el-dialog__header {
    border-bottom: 1px solid #ebf0f5;
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #ebf0f5;
  }
}
</style>
