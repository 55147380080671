<template>
  <div>
    <le-date-change-time
      :active-t.sync="activeT"
      :time-type.sync="statRequest.timeType"
      :start-time.sync="statRequest.queryStartDate"
      :end-time.sync="statRequest.queryEndDate"
    ></le-date-change-time>

    <div class="a-flex-rfsc">
      <div class="a-mt-16 a-w-100 a-br-4 a-bg-white">
        <div class="a-fs-16 a-c-333 a-fw-700 a-ptb-18 a-plr-25 a-line-b-e0">
          <span>{{ statRequest.timeType }}收益趋势</span>
        </div>
        <div class="a-ptb-14 a-plr-25">
          <div class="a-flex-rfsfs a-w-100">
            <div class="a-flex-cfsfs">
              <span class="a-c-666">总交易额(元)</span>
              <div class="a-flex-rfsc a-mt-10">
                <div class="a-fw-700">
                  <span class="a-fs-16">&yen;</span>
                  <span class="a-fs-20">{{ util.numFormat(detailAmout.thisTimeOrderAmount) }}</span>
                </div>
                <span class="a-fs-12 a-c-blue a-ml-5">
                  较上{{ statRequest.timeType }}{{ detailAmout.difAmount }}
                </span>
              </div>
            </div>
            <div class="a-flex-cfsfs a-ml-49">
              <span class="a-c-666">总订单量</span>
              <div class="a-flex-rfsc a-mt-10">
                <div class="a-fw-700">
                  <span class="a-fs-16">&yen;</span>
                  <span class="a-fs-20">{{ detailAmout.thisTimeOrderNum }}</span>
                </div>
                <span class="a-fs-12 a-c-blue a-ml-5">
                  较上{{ statRequest.timeType }}{{ detailAmout.difNum }}
                </span>
              </div>
            </div>
          </div>
          <le-echarts-line :echarts-obj="amountCharts"></le-echarts-line>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "../../../src/utils/util";
import leDateChangeTime from "../components/form/le-date-changeTime.vue";

export default {
  components: {
    "le-date-change-time": leDateChangeTime,
  },
  props: {
    stationId: {
      required: true,
    },
  },
  data() {
    return {
      util: util,
      statRequest: {
        queryStartDate: null,
        queryEndDate: null,
        timeType: "周",
      },
      activeT: 0,
      detailAmout: {},
      amountCharts: {
        id: "xxecharts",
        xAxisData: [],
        seriesData0: [],
        seriesData1: [],
        seriesName0: "订单量",
        seriesName1: "交易额",
      },
    };
  },

  watch: {
    statRequest: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.getStationOrderStat();
          this.getStationOrderStatTotal();
        });
      },
    },
    stationId: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.getStationOrderStat();
            this.getStationOrderStatTotal();
          });
        }
      },
      immediate: true,
    },
  },

  methods: {
    getStationOrderStat() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmoutList,
          params: {
            ...this.statRequest,
            stationId: this.stationId,
            statIncome: 1,
          },
        })
        .then(({ data }) => {
          this.amountCharts.xAxisData = [];
          this.amountCharts.seriesData0 = [];
          this.amountCharts.seriesData1 = [];
          data.map((item) => {
            this.amountCharts.xAxisData.push(item.fullDateText);
            this.amountCharts.seriesData0.push(item.orderNum);
            this.amountCharts.seriesData1.push(util.numFormat(item.orderAmount));
          });
        });
    },

    getStationOrderStatTotal() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmountTotal,
          params: {
            ...this.statRequest,
            stationId: this.stationId,
            statIncome: 1,
          },
        })
        .then((res) => {
          const amount = (
            res.result.data.thisTimeOrderAmount - res.result.data.lastTimeOrderAmount
          ).toFixed(0);
          const num = (res.result.data.thisTimeOrderNum - res.result.data.lastTimeOrderNum).toFixed(
            0
          );
          this.detailAmout = {
            ...res.result.data,
            difAmount: amount < 0 ? " ¥" + util.numFormat(amount) : "+ ¥" + util.numFormat(amount),
            difNum: num < 0 ? num : "+" + num,
          };
        });
    },
  },
};
</script>
