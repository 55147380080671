<template>
  <div>
    <el-card class="a-p-16 a-fs-14 a-c-333" style="width: 640px">
      <div class="a-w-100 a-flex-rsbc">
        <span class="a-fs-16 a-fw-b">高级设置项</span>
        <div class="a-flex-rcc a-cursor-p" @click="handlerEditSetting">
          <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
          <span class="a-fs-14 a-c-blue a-ml-08">编辑</span>
        </div>
      </div>
      <div class="a-mt-24">
        <span class="a-fw-b">设备过载电流</span>
      </div>
      <el-row :gutter="20" class="a-c-666 a-mt-16">
        <el-col :span="12" class="a-flex-rfsc">
          <span>普通充电(A)</span>
          <span class="a-ml-16 a-c-333">{{ station.overloadElectricityNormal }}</span>
        </el-col>
        <el-col :span="12" class="a-flex-rfsc">
          <span>大功率充电(A)</span>
          <span class="a-ml-16 a-c-333">{{ station.overloadElectricityMax }}</span>
        </el-col>
      </el-row>
      <div class="a-mt-30"></div>
    </el-card>
    <station-detail-setting-edit
      :station-id="stationId"
      :visible.sync="editVisible"
      @changeSetting="getStationDetail"
    ></station-detail-setting-edit>
  </div>
</template>

<script>
import util from "../../../src/utils/util";
import StationDetailSettingEdit from "./station-detail-setting-edit.vue";

export default {
  components: {
    "station-detail-setting-edit": StationDetailSettingEdit,
  },
  props: {
    stationId: {
      required: true,
    },
  },
  data() {
    return {
      util: util,
      station: {},
      editVisible: false,
    };
  },

  watch: {
    stationId: {
      handler(val) {
        if (val) {
          this.getStationDetail();
        }
      },
    },
  },
  methods: {
    /**
     * 获取站点详细信息
     */
    getStationDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getStationDetail,
          params: {
            stationId: this.stationId,
          },
        })
        .then(({ data }) => {
          this.station = data.station;
        });
    },

    handlerEditSetting() {
      this.editVisible = true;
    },
  },
};
</script>
