<template>
  <div :class="incomeId ? '' : 'app-body'">
    <bread-crumb v-if="!incomeId"></bread-crumb>
    <el-card class="el-main a-fs-14 comfirmed">
      <div class="comfirmed-top a-flex-rfsc">
        <div class="title a-fs-16">{{ detail.name }}</div>
        <el-tag class="a-ml-15">{{ detail.stateText }}</el-tag>
      </div>
      <div class="a-flex-cfsfs comfirmed-box">
        <div class="title a-fs-16">基本信息</div>
        <div class="a-w-100 a-flex-rfsc a-flex-wrap comfirmed-box-item">
          <div>
            <span>所属商户</span>
            <span>{{ detail.companyName }}</span>
          </div>
        </div>
      </div>
      <div class="a-flex-cfsfs comfirmed-box">
        <div class="title a-fs-16">分成比例</div>
        <div
          v-for="(item, index) in detail.targetList"
          :key="item.id"
          class="comfirmed-box-item a-w-100 a-flex-rfsc a-flex-wrap"
        >
          <div>
            <span>收益方{{ index + 1 }}</span>
            <span>{{ item.targetCompanyName }}</span>
          </div>
          <div>
            <span>分成比例</span>
            <span>{{ item.rate / 100 }}%</span>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    incomeId: {
      type: Number,
    },
  },

  data() {
    return {
      id: "",
      detail: {},
    };
  },

  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },

  watch: {
    incomeId: {
      handler(val) {
        if (val) {
          this.getDetail();
        }
      },
    },
  },

  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.id = this.$route.query.id || this.incomeId;
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getIncomeDetailInfo,
          params: {
            incomeId: parseInt(this.id),
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.detail = res.result.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.comfirmed {
  position: relative;

  &-top {
    padding: 24px 24px 16px;
    border-bottom: 1px solid #ebf0f5;
    font-weight: bold;
  }

  &-box {
    padding: 24px;
    &:nth-child(2) {
      margin-top: 0;
    }

    .title {
      font-weight: bold;
    }

    &-item {
      > div {
        width: 33.3%;
        display: flex;

        > * {
          font-size: 14px;
          margin-top: 16px;
          line-height: 22px;
          display: flex;

          &:nth-of-type(1) {
            color: #606366;
            min-width: 70px;
          }

          &:nth-of-type(2) {
            color: #303133;
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.bilibox {
  font-size: 14px;
  padding: 0 16px;
  border: 1px solid #dadde0;
  width: 50%;
  margin-top: 16px;
  margin-bottom: 8px;
  padding-bottom: 14px;

  > div {
    > * {
      width: 50%;
      text-align: center;
    }

    display: flex;

    justify-content: center;
    align-items: center;
    padding: 11px 0;
    border-bottom: 1px solid #ebf0f5;

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }

  &-title {
    padding: 24px 0 !important;
    border: none;

    > div {
      span {
        &:nth-of-type(1) {
          color: #797979;
          margin-right: 16px;
        }
      }
    }
  }

  .topone {
    background: #f5f7fa;
    color: #797979;
    border: none;
  }
}

/deep/ .el-card__body {
  padding: 0;
  // padding-bottom: 150px;
}

/deep/ .el-main {
  padding: 0;
}

.footerBox {
  // left: 255px;
  // right: 43px;
  // position: fixed;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding-bottom: 24px;
  margin-bottom: 0;
}
</style>
