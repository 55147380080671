<template>
  <el-table :data="list" :highlight-current-row="true" class="zdxx-table">
    <el-table-column label="操作时间">
      <template slot-scope="{ row }">
        <span>{{ row.opDateStr }}&nbsp;{{ row.opTimeStr }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="comment" label="操作内容" width="600"></el-table-column>
    <el-table-column prop="toStateText" label="状态"></el-table-column>
    <el-table-column prop="opUserRealName" label="操作人"></el-table-column>
  </el-table>
</template>

<script>
export default {
  components: {},
  props: {
    stationId: {
      required: true,
    },
  },
  data() {
    return {
      list: [],
      pageParam: "",
    };
  },

  watch: {
    stationId: {
      handler(val) {
        if (val) {
          this.getHistory();
        }
      },
    },
  },
  methods: {
    getHistory() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getStationTimeLine,
          params: {
            stationId: this.stationId,
          },
        })
        .then(({ data }) => {
          this.list = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
